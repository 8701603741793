.navbarHeader {
  width: 100%;
  margin-top: 5%;
  text-align: left;
  position: relative;
}

.navbarSignout {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
  margin-right: 25px;
  z-index: 10;
}

.signoutButton {
  width: 100px;
}

.cogButton {
  width: 40px;
  padding-right: 5px;
}