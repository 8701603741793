.alertWrapper {
  position: relative;
  text-align: left;
}

.alert {
	position: absolute;
	width: 310px;
	left: 50%;
	margin-left: -155px;
	z-index: 99;
	border-radius: 6px;
	background-color: #f0f0f0;
	padding: 2px 10px 0px 10px;
}

.alertWarning {
	border: 1px solid #b0a425;
}
.alertWarningHeading {
	margin-bottom: -2px;
	color: #b0a425;
}
.alertWarningMessage {
	width: 50;
	margin: 0px 0px 2px 0px;
	font-size: 10pt;
	color: #b0a425;
	line-height: 14pt;
}

.alertDanger {
	border: 1px solid #b02525;
}
.alertDangerHeading {
	margin-bottom: -2px;
	color: #b02525;
}
.alertDangerMessage {
	width: 50;
	margin: 0px 0px 2px 0px;
	font-size: 10pt;
	color: #b02525;
	line-height: 14pt;
}