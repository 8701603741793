.loginForm {
  margin-top: 20px;
}

.loginSubForm1 {
  width: 50%;
  height: 50%;
  min-width: 500px;
  min-height: 375px;
  border-radius: 6px;
}

.loginSubForm2 {
  margin-top: 10px;
}

.loginFormHeader {
  margin-bottom: 20px;
}

.alertWrapper {
  position: relative;
}

.signInMsg {
  font-size: 16pt;
}

.inputGroup {
  margin-bottom: 20px;
}

.enterpriseLoginLink {
  margin-top: 20px;
  cursor: pointer;
}

.enterpriseLoginLink:hover {
  text-decoration: underline;
}